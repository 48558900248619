// @ts-nocheck
import React, { useState } from 'react';
import { PanelData, Vector } from '@grafana/data';
import { CodeBlock } from '../CodeBlock';
import { AiFillCaretRight, AiFillCaretDown } from 'react-icons/ai';
import { css, cx } from '@emotion/css';

type tree = {
  label: string;
  fullName: string;
  meta: string;
  index: number;
  nodes: tree[];
};

function arrayToTree(input: string[], meta: string): tree[] {
  // Create an empty tree object
  const tree: tree = { index: 0, label: '', fullName: '', nodes: [] };

  // Iterate over the input array
  let index = 0;
  for (const path of input) {
    //   for (const [index, path] of input.entries()) {
    // Split the path into an array of parts using the '/' delimiter
    const parts = path.split('/');

    // Create a reference to the current node in the tree, starting at the root
    let currentNode = tree;

    // Iterate over the parts array
    for (const part of parts) {
      // Check if the current node has a child with the current part as its label
      const matchingChild = currentNode.nodes.find((node) => node.label === part);

      // If a matching child was found, set the current node to the matching child
      if (matchingChild) {
        currentNode = matchingChild;
      } else {
        // If a matching child was not found, create a new child with the current part as its label
        // and add it to the current node's nodes array
        const newChild: tree = { index, label: part, fullName: path, nodes: [], meta: meta[index] };
        currentNode.nodes.push(newChild);
        currentNode = newChild;
      }
    }
    index++;
  }

  // Return the tree
  return tree.nodes;
}

/**
 * TreeNodes
 */
const TreeNodes = ({ data }: { data: PanelData }) => {
  if (!data || !data.series || !data.series[0] || !data.series[0].fields) {
    return null;
  }

  const topic_name_arr = data.series[0].fields[1].values;
  const topic_meta_arr = data.series[0].fields[2].values;

  const treeNodes = arrayToTree(topic_name_arr, topic_meta_arr);

  return (
    <div className="flex flex-col">
      {treeNodes &&
        treeNodes.map((item) => {
          return <NavNode key={item.index} tree={item} />;
        })}
    </div>
  );
};

const NavNode = ({ tree }: { tree: tree }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  if (tree.nodes.length === 0) {
    return <NavLeaf tree={tree} />;
  }

  return (
    <div className="ml-4">
      <div className="flex gap-2">
        <button
          className="flex justify-center items-center  focus:outline-none focus:shadow-outline"
          onClick={() => setExpanded(!expanded)}
        >
          <div>{tree.label}</div>

          {expanded ? <AiFillCaretDown /> : <AiFillCaretRight />}
        </button>
      </div>

      {expanded && (
        <div className="ml-4">
          {tree.nodes.map((item) => {
            return <NavNode key={item.index} tree={item} />;
          })}
        </div>
      )}
    </div>
  );
};

const NavLeaf = ({ tree }: { tree: tree }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className="ml-4">
      <div className="flex gap-2">
        <button
          className="flex justify-center items-center  focus:outline-none focus:shadow-outline"
          onClick={() => setExpanded(!expanded)}
        >
          {tree.label}
        </button>
      </div>

      {expanded && <TabComponent tree={tree} />}
    </div>
  );
};
export default TreeNodes;

function TabComponent({ tree }: { tree: tree }) {
  const tabs = ['Overview', 'Time Series'] as const;
  const [selectedTab, setSelectedTab] = useState<'Overview' | 'Time Series'>('Overview');

  const sqlStr = `
  SELECT
    $__time(data.ts),
    CAST (data.value_string AS DECIMAL) as "${tree.label}"
  FROM data, topics
  WHERE
    $__timeFilter(ts) AND
    data.topic_id = topics.topic_id AND
    topics.topic_name LIKE ('${tree.fullName}')
  ORDER BY 1,2`;

  console.log(JSON.stringify(tree));
  return (
    <>
      <div className="mb-4">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={selectedTab}
          >
            {tabs.map((tab) => (
              <option key={tab}>{tab}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab}
                  onClick={() => setSelectedTab(tab)}
                  className={cx(
                    tab === selectedTab
                      ? 'border-indigo-200 text-indigo-100'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  aria-current={tab === selectedTab ? 'page' : undefined}
                >
                  {tab}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="mb-4">
        {selectedTab === 'Overview' && (
          <>
            <div className="flex">
              <CodeBlock title="Topic Name" language="sql" code={tree.fullName} />
            </div>
            <div className="flex">{/* <CodeBlock title="Meta" language="sql" code={tree.meta} /> */}</div>
          </>
        )}
        {selectedTab === 'Time Series' && (
          <>
            <CodeBlock language="sql" code={sqlStr} />
          </>
        )}
      </div>
    </>
  );
}
