import React, { useEffect, useState } from 'react';
import { cx } from '@emotion/css';
import Highlight, { defaultProps } from 'prism-react-renderer';

const ClipboardIcon = ({ className }: { className?: string }) => {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" className={className}>
      <path
        strokeWidth="0"
        d="M5.5 13.5v-5a2 2 0 0 1 2-2l.447-.894A2 2 0 0 1 9.737 4.5h.527a2 2 0 0 1 1.789 1.106l.447.894a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2Z"
      />
      <path
        fill="none"
        strokeLinejoin="round"
        d="M12.5 6.5a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2m5 0-.447-.894a2 2 0 0 0-1.79-1.106h-.527a2 2 0 0 0-1.789 1.106L7.5 6.5m5 0-1 1h-3l-1-1"
      />
    </svg>
  );
};

function CopyButton({ code }: { code: string }) {
  let [copyCount, setCopyCount] = useState<number>(0);
  let copied = copyCount > 0;

  useEffect(() => {
    if (copyCount > 0) {
      let timeout = setTimeout(() => setCopyCount(0), 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
    return () => {};
  }, [copyCount]);

  return (
    <button
      type="button"
      className={cx(
        'group/button absolute top-3.5 right-4 overflow-hidden rounded-full py-1 pl-2 pr-3 text-2xs font-medium opacity-0 backdrop-blur transition focus:opacity-100 group-hover:opacity-100',
        copied
          ? 'bg-emerald-400/10 ring-1 ring-inset ring-emerald-400/20'
          : 'bg-white/5 hover:bg-white/7.5 dark:bg-white/2.5 dark:hover:bg-white/5'
      )}
      onClick={() => {
        window.navigator.clipboard.writeText(code).then(() => {
          setCopyCount((count) => count + 1);
        });
      }}
    >
      <span
        aria-hidden={copied}
        className={cx(
          'pointer-events-none flex items-center gap-0.5 text-zinc-400 transition duration-300',
          copied && '-translate-y-1.5 opacity-0'
        )}
      >
        <ClipboardIcon className="h-5 w-5 fill-zinc-500/20 stroke-zinc-500 transition-colors group-hover/button:stroke-zinc-400" />
        Copy
      </span>
      <span
        aria-hidden={!copied}
        className={cx(
          'pointer-events-none absolute inset-0 flex items-center justify-center text-emerald-400 transition duration-300',
          !copied && 'translate-y-1.5 opacity-0'
        )}
      >
        Copied!
      </span>
    </button>
  );
}
type CodeBlockProps = {
  language?: 'sql' | 'json';
  code?: string;
  title?: string;
  json?: {};
};
/**
 * Code
 */
const CodeBlock = ({ json, code, language, title }: CodeBlockProps) => {
  const theCode = code ?? JSON.stringify(json, null, 2);
  const theLanguage = language ?? (json ? 'json' : 'sql');

  return (
    <div className="group dark:bg-white/2.5">
      {title && (
        <div className="flex h-9 items-center gap-2 border-y border-t-transparent border-b-white/7.5 bg-zinc-900 bg-white/2.5 px-4 dark:border-b-white/5 dark:bg-white/1">
          <span className="font-mono text-xs text-zinc-400">{title}</span>
        </div>
      )}
      <div className="relative">
        <Highlight {...defaultProps} code={theCode} language={theLanguage}>
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={cx(className, `px-4 py-1`)} style={style}>
              {tokens.map((line, i) => (
                <div key={i} {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span key={i} {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
        <CopyButton code={theCode} />
      </div>
    </div>
  );
};

export default CodeBlock;
