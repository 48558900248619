import React from 'react';

import { CodeBlock } from '../CodeBlock';
const sampleSql = `
SELECT topic_id, topic_name, metadata 
FROM topics
LIMIT 100
`;

const sample2Sql = `
SELECT topic_id, topic_name, metadata 
FROM topics
WHERE topic_name LIKE ('renton/%')
ORDER BY topic_name
`;

/**
 * EmptyPanel
 */
const HelpPanel = () => {
  return (
    <>
      <CodeBlock code={sampleSql} title="Load all topics" language="sql" />
      <CodeBlock code={sample2Sql} title="Load only Renton Topics" language="sql" />
    </>
  );
};

export default HelpPanel;
